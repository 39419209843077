import React from 'react';
import { graphql, Tracing, useStaticQuery } from 'gatsby';
import { LayoutProvider } from '../components/layout';
import {
  CannonQuality,
  SeriesBanner,
  SeriesDisplay,
  SeriesFeatures,
} from '../components/sections';
import { useTableOrMobile } from '../hooks';
import IPage from '../@types/IPage';
import { IFileImage } from '../@types';
import { ICannonSafe } from '../pages';
import { SmallSpace } from '../atoms/Space';

export interface ISeriesFeatures {
  id: number;
  cardHeader: string;
  cardTitle: string;
  cardDescription: string;
  backgroundImage: IFileImage;
}

export interface ICannonSafeSeriesResponse {
  id: string;
  slug: string;
  title: string;
  subtitle: string;
  description: string;
  enableDisplay: boolean;
  bannerAlignment: 'left' | 'center' | 'right';
  bannerBackground: IFileImage;
  features: Array<ISeriesFeatures>;
  cannon_safes: Array<ICannonSafe>;
  seriePDF: {
    url: string;
  };
}

const ProductLinesSeries = (props: IPage<ICannonSafeSeriesResponse>) => {
  const [isMobile, isTablet] = useTableOrMobile();
  const data = props.pageContext;
  const titleUpper = data.title.toUpperCase();
  const dataList = data.cannon_safes.map((li) => li.specifications);
  const dataSpecs = dataList.map((id) => id);

  //TODO change the suffix or delete it, depending on further news about the series' title
  return (
    <LayoutProvider seo locationSearch={props.location.search} titleExt={data.title + ' Series'}>
      <SeriesBanner
        bannerAlign={data.bannerAlignment}
        isMobile={isMobile}
        src={data.bannerBackground.url}
        title={`${data.title} Series`}
        subtitle={data.subtitle}
      />
      <SeriesFeatures
        title={`${data.title} FEATURES`}
        subtitle={`What makes the ${data.title} Series a valuable investment`}
        list={data.features}
      />
      <SeriesDisplay
        title={`OUR ${titleUpper} SAFES`}
        enabled={data.enableDisplay}
        description={data.subtitle}
        action={'View details'}
        list={data.cannon_safes}
      />
      <CannonQuality fileURL={data.seriePDF?.url} displayName={data.title} />
    </LayoutProvider>
  );
};

export default ProductLinesSeries;
